.btn, .heading-font {
    font-family: "Montserrat","Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 700 !important;
}

.bg-pennymac-yellow { background-color: #F1C400 !important; }
.text-pennymac-black { color: #000 !important; }
.text-pennymac-gray-darkest { color: #1B1B1B !important; }
.no-underline { text-decoration: none !important; }
.smaller { font-size: 12px; }
.shadowed {
    box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 25%);
}
.cta-hover:hover { background-color: #58adda; transform: scale(1.1);}

.fill-cyan { fill: #31AFDF; }
.fill-dark-cyan { fill: #2994BD; }
.fill-darker-cyan { fill: #278CB3; }
.stroke-dark { stroke: #003087; }
.stroke-cyan { stroke: #31AFDF; }
.icon-fill:hover .fill-cyan { fill: #1B1B1B !important; }
.icon-fill:hover .fill-dark-cyan { fill: #474747 !important; }
.icon-fill:hover .fill-darker-cyan { fill: #474747 !important; }
.icon-fill:hover .stroke-dark { stroke: #1B1B1B !important; }
.icon-fill:hover .stroke-cyan { stroke: #1B1B1B !important; }
